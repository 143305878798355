const arrayParam = ['commissionNos', 'vinNos', 'current', 'size', 'brandList']
export const resetParam = (data = {}) => {
  for (const key in data){
    if (arrayParam.indexOf(key) < 0) {
      data[key] = data[key] ?? ''
    }
  }
  return data
}

export const resetPatrial = (data = {}) => {
  for (const key in data){
    if (arrayParam.indexOf(key) < 0) {
      data[key] = ''
    }
  }
  return data
}